<template>
  <v-container v-if="authUser && order">
    <div v-if="order.provider_status == 'paid'">
      <lottie-animation
        path="./lottie/64787-success.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        @AnimControl="() => {}"
        style="width: 200px"
      />
      <v-card-title class="display-2 justify-center"> Success!! </v-card-title>
      <v-card-text class="subtitle-1 text-center">
        Coins were added to your wallet.
      </v-card-text>
      <order-coins :order="order" />
      <v-card-actions class="mt-8 mb-4">
        <v-btn
          :disabled="loading"
          class="green darken-2 white--text"
          @click="
            () => {
              $router.replace({ name: 'home' });
            }
          "
          rounded
          x-large
          block
        >
          {{ $lang("ok") }}
        </v-btn>
      </v-card-actions>
    </div>

    <div v-else>
      <lottie-animation
        path="./lottie/85340-processing-loader.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        @AnimControl="() => {}"
        style="width: 200px"
      />
      <v-card-title class="display-2 justify-center">
        {{ getStatus(order) }}
      </v-card-title>
      <v-card-text class="subtitle-1 text-center">
        Could not purchase product.
      </v-card-text>
      <v-card-actions class="mt-8 mb-4">
        <v-btn
          :disabled="loading"
          class="orange darken-2 white--text"
          @click="
            () => {
              $router.replace({ name: 'products' });
            }
          "
          rounded
          x-large
          block
        >
          {{ $lang("retry") }}
        </v-btn>
      </v-card-actions>
    </div>

    <div v-if="showMoreInfo">
      <v-card outlined elevation="0" class="px-0 mx-0 mt-4" @click="doCopy">
        <v-card-text>
          <div class="overline">Date Time:</div>
          <div class="body">{{ order.created_at }} IST</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <div class="overline">Order Id:</div>
          <div class="body">{{ order.uid }}</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text v-if="payment">
          <div class="overline">Payment Id:</div>
          <div class="body">{{ payment.payment.uid }}</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text v-if="payment">
          <div class="overline">Products:</div>
          <div
            class="body"
            v-for="(prod, idx) in order.products"
            :key="prod.id"
          >
            <v-chip>{{ idx + 1 }}</v-chip> {{ prod.title }}
          </div>
        </v-card-text>
      </v-card>
      <div class="body-2 mb-8 text-center grey--text">
        Click/Tap on above card to copy the details.
      </div>
      <my-wallet />

      <v-card-actions class="mt-10 mb-8">
        <v-btn
          :disabled="loading"
          outlined
          @click="
            () => {
              doCopy();
              $router.push({ name: 'payment-help' });
            }
          "
          rounded
          x-large
          block
        >
          {{ $lang("need help") }}
        </v-btn>
      </v-card-actions>
    </div>

    <v-btn
      :ripple="false"
      class="rounded-xl"
      block
      text
      @click="showMoreInfo = !showMoreInfo"
    >
      {{ showMoreInfo ? "show less" : "help & more info" }}
      <v-icon right v-if="showMoreInfo">mdi-arrow-up</v-icon>
      <!-- <v-icon right v-else>mdi-arrow-down</v-icon> -->
    </v-btn>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import MyWallet from "../modules/MyWallet.vue";
import OrderCoins from "../modules/OrderCoins.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  name: "invoice",
  components: {
    MyWallet,
    OrderCoins,
    LottieAnimation,
  },

  data() {
    return {
      loading: false,
      order: null,
      payment: null,
      showMoreInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    // isHindi() {
    //   return this.$store.getters.getCurrLocale == "hi";
    // },
  },
  watch: {},
  methods: {
    getStatus(order) {
      let stat = "";
      switch (order.provider_status) {
        case "attempted":
          stat = "pending...";
          break;
        case "paid":
          stat = "success!!";
          break;
        case "created":
          stat = "unpaid";
          break;
        default:
          stat = "failed!";
          break;
      }
      return this.$lang(stat);
    },
    doCopy() {
      this.$clipboard(`order id: ${this.order.uid}
        order status: ${this.order.provider_status}
        ${
          this.payment
            ? "payment id: " + this.payment.payment.uid
            : "payment id not found"
        }
      `);
      this.$store.dispatch("setAlert", {
        message: "Copied to clipboard.",
        type: "dark primary white--text",
        icon: "mdi-check-circle",
      });
    },
    fetchOrder(id) {
      bus.$emit("showWait");
      return axios
        .get("payments/order/" + id)
        .then((res) => {
          console.log("order", res.data);
          this.order = res.data;
          bus.$emit("showWait");
          if (res.data.provider_status == "paid") {
            this.fetchPayment(res.data.provider_order_id);
          }
        })
        .catch(() => {
          this.$router.replace({ name: "products" });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchPayment(id) {
      bus.$emit("showWait");
      return axios
        .get("payments/payment/" + id)
        .then((res) => {
          console.log("payment", res.data);
          this.payment = res.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    handleBtnClick() {
      this.$router.replace({ name: "home" });
    },
  },
  created() {
    this.fetchOrder(this.$route.params.id);
  },
};
</script>

