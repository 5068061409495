<template>
  <v-card class="rounded-lg" elevation="0" outlined>
    <v-card-title class="px-2">
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/coins-150.png"></v-img>
      </v-avatar>
      {{  $lang("coins") }}
      <v-spacer></v-spacer>
      {{ order.products[0].vcoin + order.products[0].gcoin }}
      {{ $lang("coins") }}
    </v-card-title>
    <v-divider></v-divider>

    <v-card-actions>
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/coin-single-50.png"></v-img>
      </v-avatar>
      {{ $lang("vCoin") }}
      <v-spacer></v-spacer>
      {{ order.products[0].vcoin }}
      {{ $lang("coins") }}
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-actions>
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/coin-single-50.png"></v-img>
      </v-avatar>
      {{ $lang("gCoin") }}
      <v-spacer></v-spacer>
      {{ order.products[0].gcoin }}
      {{ $lang("coins") }}
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-actions>
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/rupee.png"></v-img>
      </v-avatar>
      {{ $lang("amount") }}
      <v-spacer></v-spacer>
      {{ order.currency == 'INR' ? '₹' : order.currency }} {{ order.amount /100 }} /-
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "order-coins",
  components: {},
  props:['order', 'payment'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
