var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authUser && _vm.order)?_c('v-container',[(_vm.order.provider_status == 'paid')?_c('div',[_c('lottie-animation',{staticStyle:{"width":"200px"},attrs:{"path":"./lottie/64787-success.json","loop":true,"autoPlay":true,"speed":1},on:{"AnimControl":function () {}}}),_c('v-card-title',{staticClass:"display-2 justify-center"},[_vm._v(" Success!! ")]),_c('v-card-text',{staticClass:"subtitle-1 text-center"},[_vm._v(" Coins were added to your wallet. ")]),_c('order-coins',{attrs:{"order":_vm.order}}),_c('v-card-actions',{staticClass:"mt-8 mb-4"},[_c('v-btn',{staticClass:"green darken-2 white--text",attrs:{"disabled":_vm.loading,"rounded":"","x-large":"","block":""},on:{"click":function () {
            _vm.$router.replace({ name: 'home' });
          }}},[_vm._v(" "+_vm._s(_vm.$lang("ok"))+" ")])],1)],1):_c('div',[_c('lottie-animation',{staticStyle:{"width":"200px"},attrs:{"path":"./lottie/85340-processing-loader.json","loop":true,"autoPlay":true,"speed":1},on:{"AnimControl":function () {}}}),_c('v-card-title',{staticClass:"display-2 justify-center"},[_vm._v(" "+_vm._s(_vm.getStatus(_vm.order))+" ")]),_c('v-card-text',{staticClass:"subtitle-1 text-center"},[_vm._v(" Could not purchase product. ")]),_c('v-card-actions',{staticClass:"mt-8 mb-4"},[_c('v-btn',{staticClass:"orange darken-2 white--text",attrs:{"disabled":_vm.loading,"rounded":"","x-large":"","block":""},on:{"click":function () {
            _vm.$router.replace({ name: 'products' });
          }}},[_vm._v(" "+_vm._s(_vm.$lang("retry"))+" ")])],1)],1),(_vm.showMoreInfo)?_c('div',[_c('v-card',{staticClass:"px-0 mx-0 mt-4",attrs:{"outlined":"","elevation":"0"},on:{"click":_vm.doCopy}},[_c('v-card-text',[_c('div',{staticClass:"overline"},[_vm._v("Date Time:")]),_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.order.created_at)+" IST")])]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"overline"},[_vm._v("Order Id:")]),_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.order.uid))])]),_c('v-divider'),(_vm.payment)?_c('v-card-text',[_c('div',{staticClass:"overline"},[_vm._v("Payment Id:")]),_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.payment.payment.uid))])]):_vm._e(),_c('v-divider'),(_vm.payment)?_c('v-card-text',[_c('div',{staticClass:"overline"},[_vm._v("Products:")]),_vm._l((_vm.order.products),function(prod,idx){return _c('div',{key:prod.id,staticClass:"body"},[_c('v-chip',[_vm._v(_vm._s(idx + 1))]),_vm._v(" "+_vm._s(prod.title)+" ")],1)})],2):_vm._e()],1),_c('div',{staticClass:"body-2 mb-8 text-center grey--text"},[_vm._v(" Click/Tap on above card to copy the details. ")]),_c('my-wallet'),_c('v-card-actions',{staticClass:"mt-10 mb-8"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"outlined":"","rounded":"","x-large":"","block":""},on:{"click":function () {
            _vm.doCopy();
            _vm.$router.push({ name: 'payment-help' });
          }}},[_vm._v(" "+_vm._s(_vm.$lang("need help"))+" ")])],1)],1):_vm._e(),_c('v-btn',{staticClass:"rounded-xl",attrs:{"ripple":false,"block":"","text":""},on:{"click":function($event){_vm.showMoreInfo = !_vm.showMoreInfo}}},[_vm._v(" "+_vm._s(_vm.showMoreInfo ? "show less" : "help & more info")+" "),(_vm.showMoreInfo)?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-up")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }